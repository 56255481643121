import store from '../store'

export function storeUserInfo() {
  return store.state.userInfo
}

export function companyInfo() {
  return store.state.companyInfo
}

export const getUrl = (() => {
  const objUrl = {}
  if (window.location.href.indexOf('?') > 0) {
    const url = window.location.href.split('?')[1]
    // a=123 b=456
    const urlArr = url.match(/[^?&]+=[^?&]+/g)
    if (urlArr && urlArr.length > 0) {
      urlArr.forEach(item => {
        const curr = item.split('=')
        const key = curr[0]
        const value = curr[1]
        objUrl[key] = value
      })
    }
  }

  return objUrl
})()

// 获取唯一值
export const getUid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const hiddenCheckbox = () => {
  const displayCheckboxList = document.getElementsByName('disabled')
  displayCheckboxList.forEach(item => {
    item.parentNode.parentNode.parentNode.className = item.className + ' hidden'
  })
}

export const hiddenCheckbox2 = () => {
  const displayCheckboxList = document.getElementsByName('kehulaiwen')
  console.log('displayCheckboxList', displayCheckboxList)
  displayCheckboxList.forEach(item => {
    item.parentNode.parentNode.parentNode.className = item.className + ' hidden'
  })
}

export const utils = {
  getTrademarkPath(flowTableCode = '') {
    const bzxPcodeMap = [
      'tr_extent',
      'tr_change',
      'tr_change_agent',
      'tr_off',
      'tr_append',
      'tr_modify',
      'tr_permit',
      'tr_concel',
      'tr_transfer'
      // 'tr_tf' //去掉这个 周华说的 20210922
    ]
    if (flowTableCode === 'tr_bohui') {
      return 'bh';
    } else if (bzxPcodeMap.includes(flowTableCode)) {
      return 'bzx';
    } else if (flowTableCode === 'tr_reg') { // 增加 tr_ 和 pt 判断 20210630
      return 'tr';
    } else if (flowTableCode === 'cr_soft' || flowTableCode === 'cr_pro') {
      return 'cr'
    } else {
      return 'qt'; // 其余都跳转到其它订单 20210630
    }
  },
  getUid,
  hiddenCheckbox
}
