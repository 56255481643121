import axios from 'axios'
import { storeUserInfo } from './common'
const request = ({
  baseURL,
  headers
}) => {
  const instance = axios.create({
    baseURL,
    timeout: 500000,
    headers
  })

  // request interceptor
  instance.interceptors.request.use(config => {
    const data = config.data;
    if (!config.headers.token) {
      // TODO: 请求头如果没有token

      // 处理 headers 中token问题
      const headers = config.headers
      const accesstokens = storeUserInfo().accessToken || '-1'

      if ((data && config.headers && config.headers.module && config.headers.module === 'toolset')) {
        let token = ''
        if (accesstokens === '-1') {
          token = storeUserInfo().token
        } else {
          token = storeUserInfo().token + '##' + accesstokens || ''
        }

        config.headers = {
          token,
          ...headers
        }
      } else {
        const token = storeUserInfo().token || ''
        config.headers = {
          token,
          ...headers
        }
      }
    }

    return config
  }, error => {
    // Do something with request error
    console.log(error)
    return Promise.reject(error)
  });

  // response interceptor
  instance.interceptors.response.use(response => {
    const res = response
    const data = response.data
    // 如果成功直接返回接口数据
    if (data.code === 10000 || data.code === 0 || data.filePath || data) {
      return res
    } else {
      console.log(response)
      return Promise.reject(response.data.message || 'error')
    }
    // return response
  }, error => {
    // Do something with response error
    console.log('err' + error.response) // for debug
    return Promise.reject(error)
  })

  return instance
}

export default request
