import request from './axiosInstance'

export const current = request({
  baseURL: process.env.VUE_APP_REGISTERAPI,
  headers: {
    'Content-Type': 'application/json',
    module: 'bi',
    'app-id': '6e70493db312394692779153d07ff7c5',
    version: '1',
    timestamps: '1550563990000'
  }
})
export const baseApi = request({
  // url: 'http://base-api.zhiguoguo.net'
  baseURL: process.env.VUE_APP_BASEAPI,
  headers: {
    'Content-Type': 'application/json'
  }

})
